<template>
  <div id="coin">
    <div class="content-header">
      <div class="title-1">
        <router-link to="/purchase-coin"
          ><img src="../assets/back_arrow.svg" />Purchase Credits
        </router-link>
      </div>
    </div>

    <div class="single-games" v-if="packageDetails.coin_amount">
      <div class="purchase-c">
        <h5><img src="../assets/gold-coins-icon.svg" /></h5>
        <h6>
          <span>{{ totalCoin }}</span>
        </h6>
        <h3>
          You are buying <span> {{ packageDetails.coin_amount }} </span>credits
          at <span>{{ packageDetails.price }} </span> taka. Kindly confirm.
        </h3>

        <div class="confirm-area">
          <button
            type="submit"
            class="submit-btn3"
            id="coinPurchase"
            @click="robiPurchase(packageId, phone)"
          >
            Proceed
          </button>
          <router-link to="/purchase-coin" tag="button" class="submit-btn4"
            >Goback
          </router-link>
        </div>
      </div>
    </div>
    <div class="full-loader lds-circle" style="display: none;">
      <div class="main-loader">
        <div class="loader-text">We are completing your purchase</div>
        <div class="loader-text">
          Please do not close the browser while we complete the request.
        </div>
        <div class="loader"></div>
      </div>
    </div>
    <footer>
      <div class="main-footer">
        <div class="social-icon"></div>
      </div>
    </footer>

    <div class="modal-background" id="regModal">
      <div class="modal-content">
        <span class="close" onclick="hideModal('regModal')"></span>
        <h5>
          You have successfully purchased
          {{ packageDetails.coin_amount }} credits at
          {{ packageDetails.price }} BDT. This coins can be found in clicking
          the coin section of Xoshplay
        </h5>
        <div class="tick-cross-btn">
          <p>Redirecting to previous page in 2 seconds</p>
        </div>
      </div>
    </div>

    <div class="modal-background" id="chargingError">
      <div class="modal-content">
        <span class="close" onclick="hideChargingError('chargingError')"></span>
        <h5>
          {{ errorMsg }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import md5 from "md5";

export default {
  name: "BuyCoin",
  data: () => {
    return {
      packageDetails: [],
      totalCoin: 0,
      payment: 0,
      host: null,
      phone: localStorage.getItem("phone"),
      packageError: "",
      packageId: 0,
      errorMsg: `Your transaction request has been failed. Make sure you have enough balance in your account and try again after a minute.`
    };
  },
  created() {
    this.host = this.$root.url();
    const path = "packageDetails";
    const url = `${this.host}/${path}?packageId=${
      this.$route.params.packageId
    }&phone=${localStorage.getItem("phone")}`;
    axios
      .get(url)
      .then(response => {
        console.log(response.data);
        if (response.data.success === true) {
          this.packageDetails = response.data.coinDetails;
          this.totalCoin = response.data.totalCoin;
          this.payment = parseInt(response.data.payment);
          this.packageId = this.$route.params.packageId;
          localStorage.setItem(
            "service_id",
            response.data.coinDetails.service_id
          );
          localStorage.setItem("message", response.data.coinDetails.message);
        } else {
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
        }
      })
      .catch(error => {
        //alert(error);
        console.log(error);
      });
  },
  methods: {
    showModal: function(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    hideModal: function(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
      // location.href = `${config.clientHost}/purchase-coin`;
      this.$router.push({ name: "PurchaseCoin" });
    },
    hideChargingError: function(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    },
    robiPurchase: function(packageId = 0, phone = null) {
      let vm = this;
      let curTime = Date.now();
      let randomNumber = Math.floor(100000000 + Math.random() * 900000000);
      let individualTransactionId =
        this.payment === 4
          ? curTime + phone + randomNumber
          : md5(curTime + phone + randomNumber);
      try {
        let service_id = localStorage.getItem("service_id");
        let message = localStorage.getItem("message");
        let amount = this.packageDetails.amount;
        let amountWithVat = this.packageDetails.amount_with_vat;
        let description = this.packageDetails.description;
        let operatorCharging = this.payment;
        let serviceType = this.packageDetails.package_type;
        $(".single-games").hide();
        $(".lds-circle").show();
        if (!phone || !service_id) {
          alert("Please login XoshPlay.com");
          $(".single-games").show();
          $(".lds-circle").hide();
          return false;
        } else {
          const chargingPath = "robiCharging";
          let chargingUrl = `${this.host}/${chargingPath}?msisdn=${phone}&service_id=${service_id}&msg=${message}&packageId=${packageId}&individualTransactionId=${individualTransactionId}&amount=${amount}&amountWithVat=${amountWithVat}&description=${description}&operatorCharging=${operatorCharging}&serviceType=${serviceType}`;
          chargingUrl = encodeURI(chargingUrl);
          //alert(chargingUrl);
          $.ajax({
            url: chargingUrl,
            method: "GET",
            dataType: "json",
            success: function(chargingResponse) {
              let {
                success,
                code,
                message,
                chargeRedirectUrl
              } = chargingResponse;
              if (success) {
                if (operatorCharging == 2) {
                  window.location.href = chargeRedirectUrl;
                  return true;
                }
                vm.showModal("regModal");
                $(".single-games").show();
                $(".lds-circle").hide();
                setTimeout(function() {
                  vm.$router.push({ name: "PurchaseCoin" });
                }, 2000);
              } else {
                if (code === 3004) {
                  localStorage.clear();
                  vm.$router.push({ name: "Registration" });
                }
                if (code === 304) {
                  vm.errorMsg = message;
                }
                vm.showModal("chargingError");
                $(".single-games").show();
                $(".lds-circle").hide();
              }
            },
            error: function(error) {
              console.log(error);
              vm.showModal("chargingError");
              $("#coinPurchase").show();
              $(".lds-circle").hide();
            }
          });
        }
      } catch (e) {
        console.log(e);
        vm.showModal("chargingError");
        $(".single-games").show();
        $(".lds-circle").hide();
      }
    }
  }
};
</script>
